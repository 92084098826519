import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import {
  DELETE_CUSTOM_LIST_ITEM,
  GetCustomListTypes,
  GetProductAttributesTypes,
  GetProductsTypes,
  MOVE_CUSTOM_LIST_ITEM,
} from './product.actions';

const initialState = {
  data: [],
  isLoading: false,
  wishlist: [],
  attributes: null,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetProductsTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetCustomListTypes.SUCCESS:
      return {
        ...state,
        wishlist: action.payload.items,
      };

    case GetProductAttributesTypes.SUCCESS:
      return {
        ...state,
        attributes: action.payload,
      };

    case GetProductsTypes.SUCCESS:
      return {
        ...state,
        data: Array.isArray(action.payload?.data)
          ? action.payload.data
              .map(({ variants, ...product }) => ({
                ...product,
                variants: variants.filter(variant => variant.available !== false),
              }))
              .filter(({ variants }) => variants.length)
          : [],
        isLoading: false,
      };

    case GetProductsTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case MOVE_CUSTOM_LIST_ITEM: {
      const newOrderedWishList = [...state.wishlist];
      const movableItem = newOrderedWishList.splice(action.payload.sourceIndex, 1);
      newOrderedWishList.splice(action.payload.destinationIndex, 0, ...movableItem);

      return {
        ...state,
        wishlist: newOrderedWishList,
      };
    }

    case DELETE_CUSTOM_LIST_ITEM:
      return {
        ...state,
        wishlist: state.wishlist.filter((_, i) => i !== action.payload.index),
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
