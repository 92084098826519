import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import './NewSubscriptionButton.scss';

export const NewSubscriptionButton = ({ onClick, isLoading }) => (
  <div className="text-center mt-4 pt-4">
    <Button onClick={onClick} color="light" className="btn-light-darker">
      {isLoading ? 'Redirecting...' : 'Add New Subscription'}
    </Button>
  </div>
);

NewSubscriptionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
