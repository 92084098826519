import React from 'react';
import { ProductName } from '@bottomless/common/src/components/ProductV2/components';
import PropTypes from 'prop-types';

export const AccountName = ({ account, withMore, withMoreText }) => (
  <>
    {account.product.product.personalized_rotation ? (
      <ProductName user={account} />
    ) : (
      account.product.product.name || null
    )}
    {withMore && account.lineItems?.length > 0 && (
      <span className="text-secondary"> (+{account.lineItems.length})</span>
    )}
    {withMoreText && account.lineItems?.length > 0 && (
      <span className="text-secondary font-weight-normal"> +{account.lineItems.length}&nbsp;more</span>
    )}
  </>
);

AccountName.propTypes = {
  account: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        personalized_rotation: PropTypes.bool,
      }).isRequired,
    }).isRequired,
    lineItems: PropTypes.array,
  }).isRequired,
  withMore: PropTypes.bool,
  withMoreText: PropTypes.bool,
};
