import { LogoutTypes, ChangeScaleTypes, CLEAR_USER_VENDOR } from '@bottomless/common/store';
import {
  AcceptPhoneTypes,
  GetAlertSettingsTypes,
  GetCleanDataTypes,
  GetMeTypes,
  GetUserVendorTypes,
  OrderingAggresionTypes,
  PauseAccountTypes,
  RejectPhoneTypes,
  SET_LOGGED_IN,
  SetAlertSettingsTypes,
  SetDumbPeriodTypes,
  TextAlertsTypes,
  VerifyAddressTypes,
  VerifyPhoneTypes,
  VerifyStripeTypes,
  GetAccountsTypes,
  GetCreditsTypes,
  ShareDataTypes,
} from './user.actions';
import { OPTIMISTIC_LINE_ITEMS_AMOUNTS_UPDATE, UpdateLineItemsAmountsTypes } from '../product/product.actions';

const initialState = {
  me: null,
  isLoggedIn: false,
  cleanData: {},
  credits: {},
  alertSettings: null,
  isLoading: false,
  vendor: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetMeTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetMeTypes.SUCCESS:
      return {
        ...state,
        me: {
          ...action.payload,
        },
        isLoading: false,
      };

    case VerifyAddressTypes.SUCCESS:
    case VerifyStripeTypes.SUCCESS:
    case PauseAccountTypes.SUCCESS:
    case OrderingAggresionTypes.SUCCESS:
    case TextAlertsTypes.SUCCESS:
    case VerifyPhoneTypes.SUCCESS:
    case UpdateLineItemsAmountsTypes.SUCCESS:
    case ShareDataTypes.SUCCESS:
      if (!action.payload.product?.product?.category) {
        delete action.payload.product;
      }
      return {
        ...state,
        me: {
          ...state.me,
          ...action.payload,
        },
        isLoading: false,
      };

    case OPTIMISTIC_LINE_ITEMS_AMOUNTS_UPDATE: {
      const newMainProduct = action.payload.lineItems.find(lineItem => !lineItem._id);
      const newLineItems = [...(state.me.lineItems || [])];

      return {
        ...state,
        me: {
          ...state.me,
          quantity: newMainProduct.quantity,
          lineItems: newLineItems
            .map(lineItem => ({
              ...lineItem,
              quantity: action.payload.lineItems.find(l => l._id === lineItem._id).quantity,
            }))
            .filter(lineItem => lineItem.quantity),
        },
      };
    }

    case GetAlertSettingsTypes.SUCCESS:
    case SetAlertSettingsTypes.SUCCESS:
      return {
        ...state,
        alertSettings: action.payload,
      };

    case GetCleanDataTypes.SUCCESS:
      return {
        ...state,
        cleanData: action.payload,
      };

    case SetDumbPeriodTypes.SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          dumb_period: action.payload.dumb_period,
          subscriptionType: action.payload.subscriptionType,
        },
      };

    case AcceptPhoneTypes.SUCCESS:
    case RejectPhoneTypes.SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          local: {
            ...state.me.local,
            pendingPhoneNumber: undefined,
          },
          phone: action.payload.phone,
        },
      };

    case GetUserVendorTypes.SUCCESS:
      return {
        ...state,
        vendor: action.payload,
      };

    case GetCreditsTypes.SUCCESS:
      return {
        ...state,
        credits: action.payload,
      };

    case GetMeTypes.FAILURE:
      return {
        ...state,
        me: null,
        isLoading: false,
      };

    case SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true,
      };

    case CLEAR_USER_VENDOR:
      return {
        ...state,
        vendor: null,
      };

    case LogoutTypes.SUCCESS:
      return initialState;

    case ChangeScaleTypes.SUCCESS:
      return {
        ...initialState,
        isLoggedIn: true,
        me: state.me,
      };

    case GetAccountsTypes.SUCCESS:
      return {
        ...state,
        accounts: action.payload,
      };

    default:
      return state;
  }
};
