import React from 'react';
import { useHistory } from 'react-router-dom';
import { useConfigValue } from '../../hooks/use-config-value.hook';
import { AccountPage } from './AccountPage';

export const SubscriptionsPage = () => {
  const history = useHistory();
  const useEmbedAccountPicker = useConfigValue('useEmbedAccountPicker');

  if (!useEmbedAccountPicker) {
    history.push('/portal-select?alreadyLogged=true');
    return null;
  }

  return <AccountPage />;
};
