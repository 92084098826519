import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetMeTypes = createAction('Users', 'Get me');

export const getMeAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/me',
    method: 'GET',
    types: [GetMeTypes.REQUEST, GetMeTypes.SUCCESS, GetMeTypes.FAILURE],
  },
});

export const GetAccountsTypes = createAction('Users', 'Get accounts');

export const getAccountsAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/accounts',
    method: 'GET',
    types: [GetAccountsTypes.REQUEST, GetAccountsTypes.SUCCESS, GetAccountsTypes.FAILURE],
  },
});

export const MakeBottomlessProductTypes = createAction('Users', 'Make bottomless product');

export const makeBottomlessProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/products/${id}/make-bottomless`,
    method: 'post',
    body: data,
    types: [MakeBottomlessProductTypes.REQUEST, MakeBottomlessProductTypes.SUCCESS, MakeBottomlessProductTypes.FAILURE],
  },
});

export const VerifyAddressTypes = createAction('Users', 'Verify address');

export const verifyAddressAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/verify-address',
    method: 'POST',
    body: data,
    types: [VerifyAddressTypes.REQUEST, VerifyAddressTypes.SUCCESS, VerifyAddressTypes.FAILURE],
  },
});

export const VerifyStripeTypes = createAction('Users', 'Verify stripe');

export const verifyStripeAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/verify-stripe',
    method: 'POST',
    body: data,
    types: [VerifyStripeTypes.REQUEST, VerifyStripeTypes.SUCCESS, VerifyStripeTypes.FAILURE],
  },
});

export const OrderingAggresionTypes = createAction('Users', 'Ordering aggression');

export const orderingAggresionAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/ordering-aggression',
    method: 'POST',
    body: data,
    types: [OrderingAggresionTypes.REQUEST, OrderingAggresionTypes.SUCCESS, OrderingAggresionTypes.FAILURE],
  },
});

export const GetAlertSettingsTypes = createAction('Users', 'Get alert settings');

export const getAlertSettingsAction = () => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/users/alert-settings`,
    method: 'GET',
    types: [GetAlertSettingsTypes.REQUEST, GetAlertSettingsTypes.SUCCESS, GetAlertSettingsTypes.FAILURE],
  },
});

export const SetAlertSettingsTypes = createAction('Users', 'Set alert settings');

export const setAlertSettingsAction = data => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/users/alert-settings`,
    method: 'POST',
    body: data,
    types: [SetAlertSettingsTypes.REQUEST, SetAlertSettingsTypes.SUCCESS, SetAlertSettingsTypes.FAILURE],
  },
});

export const TextAlertsTypes = createAction('Users', 'Text alerts');

export const textAlertsAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/text-alerts',
    method: 'POST',
    body: data,
    types: [TextAlertsTypes.REQUEST, TextAlertsTypes.SUCCESS, TextAlertsTypes.FAILURE],
  },
});

export const VerifyPhoneTypes = createAction('Users', 'Verify phone');

export const verifyPhoneAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/verify-phone',
    method: 'POST',
    body: data,
    types: [VerifyPhoneTypes.REQUEST, VerifyPhoneTypes.SUCCESS, VerifyPhoneTypes.FAILURE],
  },
});

export const GetCleanDataTypes = createAction('Users', 'Get clean data');

export const getCleanedDataAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/clean-data',
    method: 'GET',
    types: [GetCleanDataTypes.REQUEST, GetCleanDataTypes.SUCCESS, GetCleanDataTypes.FAILURE],
  },
});

export const PauseAccountTypes = createAction('Users', 'Pause account');

export const pauseAccountAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/pause-account',
    method: 'POST',
    body: data,
    types: [PauseAccountTypes.REQUEST, PauseAccountTypes.SUCCESS, PauseAccountTypes.FAILURE],
  },
});

export const SetDumbPeriodTypes = createAction('Users', 'Set dumb period');

export const setDumbPeriodAction = (data, place) => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/dumb-period',
    method: 'POST',
    body: { ...data, switchToSbuPricingRule: true },
    ...(place ? { headers: { 'X-SWITCH-TO-SBU': place } } : {}),
    types: [SetDumbPeriodTypes.REQUEST, SetDumbPeriodTypes.SUCCESS, SetDumbPeriodTypes.FAILURE],
  },
});

export const CloseAccountTypes = createAction('Users', 'Close account');

export const closeAccountAction = data => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/users/close-account`,
    method: 'POST',
    body: data,
    types: [CloseAccountTypes.REQUEST, CloseAccountTypes.SUCCESS, CloseAccountTypes.FAILURE],
  },
});

export const GetUserCleanDataTypes = createAction('Users', 'Get user clean data');

export const getUserCleanedDataAction = id => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/users/clean-data/${id}`,
    method: 'GET',
    types: [GetUserCleanDataTypes.REQUEST, GetUserCleanDataTypes.SUCCESS, GetUserCleanDataTypes.FAILURE],
  },
});

export const SET_LOGGED_IN = 'Users/Logged in';

export const setUserLoggedInAction = () => ({ type: SET_LOGGED_IN });

export const GetUserVendorTypes = createAction('Users', 'Get User Vendor');

export const getUserVendorAction = id => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/vendors/id/${id}`,
    method: 'GET',
    types: [GetUserVendorTypes.REQUEST, GetUserVendorTypes.SUCCESS, GetUserVendorTypes.FAILURE],
  },
});

export const AcceptPhoneTypes = createAction('Users', 'Accept phone');

export const acceptPhoneAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/accept-phone',
    method: 'PATCH',
    types: [AcceptPhoneTypes.REQUEST, AcceptPhoneTypes.SUCCESS, AcceptPhoneTypes.FAILURE],
  },
});

export const RejectPhoneTypes = createAction('Users', 'Reject phone');

export const rejectPhoneAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/reject-phone',
    method: 'PATCH',
    types: [RejectPhoneTypes.REQUEST, RejectPhoneTypes.SUCCESS, RejectPhoneTypes.FAILURE],
  },
});

export const SendPaymentUpdateEmailTypes = createAction('Users', 'Send payment update email');

export const sendPaymentUpdateEmailAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/payment-update-link',
    method: 'POST',
    types: [
      SendPaymentUpdateEmailTypes.REQUEST,
      SendPaymentUpdateEmailTypes.SUCCESS,
      SendPaymentUpdateEmailTypes.FAILURE,
    ],
  },
});

export const AddLineItemTypes = createAction('Users', 'Add line item');

export const addLineItemAction = data => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/products/line-item`,
    method: 'POST',
    body: data,
    types: [AddLineItemTypes.REQUEST, AddLineItemTypes.SUCCESS, AddLineItemTypes.FAILURE],
  },
});

export const UpdateLineItemTypes = createAction('Users', 'Update line item');

export const updateLineItemAction = (id, body) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/products/line-item/${id}`,
    method: 'PATCH',
    body,
    types: [UpdateLineItemTypes.REQUEST, UpdateLineItemTypes.SUCCESS, UpdateLineItemTypes.FAILURE],
  },
});

export const GetCreditsTypes = createAction('Users', 'Get credits');

export const getCreditsAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/credits',
    method: 'GET',
    types: [GetCreditsTypes.REQUEST, GetCreditsTypes.SUCCESS, GetCreditsTypes.FAILURE],
  },
});

export const ContinueGiftTypes = createAction('Users', 'Continue gift');

export const continueGiftAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/continue-gift',
    method: 'POST',
    body: data,
    types: [ContinueGiftTypes.REQUEST, ContinueGiftTypes.SUCCESS, ContinueGiftTypes.FAILURE],
  },
});

export const GetUserStatsTypes = createAction('Users', 'Get user stats');

export const getUserStatsAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/stats',
    method: 'GET',
    types: [GetUserStatsTypes.REQUEST, GetCleanDataTypes.SUCCESS, GetUserStatsTypes.FAILURE],
  },
});

export const RequestScaleAssistanceTypes = createAction('Users', 'Request scale assistance');

export const requestScaleAssistanceAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/scale-assistance',
    method: 'POST',
    body: data,
    types: [
      RequestScaleAssistanceTypes.REQUEST,
      RequestScaleAssistanceTypes.SUCCESS,
      RequestScaleAssistanceTypes.FAILURE,
    ],
  },
});

export const ShareDataTypes = createAction('Users', 'Share data');

export const shareDataAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/users/share-data',
    method: 'POST',
    body: data,
    types: [ShareDataTypes.REQUEST, ShareDataTypes.SUCCESS, ShareDataTypes.FAILURE],
  },
});
