import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import {
  GetInTransitionCountTypes,
  GetInTransitionOrdersTypes,
  GetLastOrderTypes,
  GetLastSubscriptionOrderTypes,
  GetOrdersTypes,
  GetUpcomingOrderTypes,
  OverrideDateTypes,
  SkipOrderTypes,
} from './order.actions';

const initialState = {
  data: [],
  lastOrder: null,
  lastSubscriptionOrder: null,
  upcomingOrders: null,
  inTransition: [],
  isInTransitionLoading: false,
  isLoading: false,
  count: {},
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetOrdersTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetInTransitionOrdersTypes.REQUEST:
      return {
        ...state,
        isInTransitionLoading: true,
      };

    case GetInTransitionOrdersTypes.SUCCESS:
      return {
        ...state,
        isInTransitionLoading: false,
        inTransition: action.payload.data,
        count: {
          orders: action.payload.data.length,
          slowLocal: state.count?.slowLocal || 0,
          sum: (state.count?.slowLocal || 0) + action.payload.data.length,
        },
      };

    case GetOrdersTypes.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };

    case GetLastOrderTypes.SUCCESS:
      return {
        ...state,
        lastOrder: action.payload,
      };

    case GetLastSubscriptionOrderTypes.SUCCESS:
      return {
        ...state,
        lastSubscriptionOrder: action.payload,
      };

    case SkipOrderTypes.SUCCESS:
    case GetUpcomingOrderTypes.SUCCESS:
      return {
        ...state,
        upcomingOrders: action.payload,
      };

    case OverrideDateTypes.SUCCESS: {
      if (!state.upcomingOrders?.orders?.find(({ order }) => order._id === action.payload._id)) {
        return state;
      }

      return {
        ...state,
        upcomingOrders: {
          ...state.upcomingOrders,
          orders: state.upcomingOrders.orders.map(order =>
            order.order._id === action.payload._id
              ? {
                  ...order,
                  order: { ...order.order, override_fulfillment_date: action.payload.override_fulfillment_date },
                }
              : order
          ),
        },
      };
    }

    case GetInTransitionOrdersTypes.FAILURE:
      return {
        ...state,
        isInTransitionLoading: false,
      };

    case GetInTransitionCountTypes.SUCCESS: {
      return {
        ...state,
        count: action.payload,
      };
    }

    case GetOrdersTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
