import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { UserPlus } from 'react-feather';

export const NewSubscriptionButton = ({ onClick, isLoading }) => (
  <Button onClick={onClick} color="text" className="d-flex align-items-center">
    <UserPlus size={18} className="mr-2" />
    <span className="text-underline">{isLoading ? 'Redirecting...' : 'New Subscription'}</span>
  </Button>
);

NewSubscriptionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
