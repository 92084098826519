import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetOrdersTypes = createAction('Orders', 'Get all');

export const getOrdersAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/orders',
    method: 'GET',
    types: [GetOrdersTypes.REQUEST, GetOrdersTypes.SUCCESS, GetOrdersTypes.FAILURE],
  },
});

export const GetInTransitionOrdersTypes = createAction('Orders', 'Get in transition');

export const getInTransitionOrdersAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/in-transition',
    method: 'GET',
    types: [GetInTransitionOrdersTypes.REQUEST, GetInTransitionOrdersTypes.SUCCESS, GetInTransitionOrdersTypes.FAILURE],
  },
});

export const GetInTransitionCountTypes = createAction('Orders', 'Get in transition count');

export const getInTransitionCountAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/in-transition/count',
    method: 'GET',
    types: [GetInTransitionCountTypes.REQUEST, GetInTransitionCountTypes.SUCCESS, GetInTransitionCountTypes.FAILURE],
  },
});

export const GetOrderStatusTypes = createAction('Orders', 'Get order status');

export const getOrderStatusAction = id => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/orders/${id}/status`,
    method: 'GET',
    types: [GetOrderStatusTypes.REQUEST, GetOrderStatusTypes.SUCCESS, GetOrderStatusTypes.FAILURE],
  },
});

export const CancelOrderTypes = createAction('Orders', 'Cancel order');

export const cancelOrderAction = id => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/orders/${id}/cancel`,
    method: 'POST',
    types: [CancelOrderTypes.REQUEST, CancelOrderTypes.SUCCESS, CancelOrderTypes.FAILURE],
  },
});

export const GetLastOrderTypes = createAction('Orders', 'Get last order');

export const getLastOrderAction = () => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/orders/last`,
    method: 'GET',
    types: [GetLastOrderTypes.REQUEST, GetLastOrderTypes.SUCCESS, GetLastOrderTypes.FAILURE],
  },
});

export const GetLastSubscriptionOrderTypes = createAction('Orders', 'Get last subscription order');

export const getLastSubscriptionOrderAction = () => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/orders/last-subscription`,
    method: 'GET',
    types: [
      GetLastSubscriptionOrderTypes.REQUEST,
      GetLastSubscriptionOrderTypes.SUCCESS,
      GetLastSubscriptionOrderTypes.FAILURE,
    ],
  },
});

export const OverrideDateTypes = createAction('Orders', 'Override date');

export const overrideDateAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/orders/override-date${id ? `/${id}` : ''}`,
    method: 'POST',
    body: data,
    types: [OverrideDateTypes.REQUEST, OverrideDateTypes.SUCCESS, OverrideDateTypes.FAILURE],
  },
});

export const TriggerOrderAction = createAction('Orders', 'Trigger order');

export const triggerOrderAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/orders/future/trigger',
    method: 'POST',
    body: data,
    types: [TriggerOrderAction.REQUEST, TriggerOrderAction.SUCCESS, TriggerOrderAction.FAILURE],
  },
});

export const GetUpcomingOrderTypes = createAction('Users', 'Get upcoming order');

export const getUpcomingOrderAction = () => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/orders/upcoming`,
    method: 'GET',
    types: [GetUpcomingOrderTypes.REQUEST, GetUpcomingOrderTypes.SUCCESS, GetUpcomingOrderTypes.FAILURE],
  },
});

export const SetProductTypes = createAction('Orders', 'Set order');

export const setProductAction = (orderId, productId, variantId) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/orders/${orderId}/set/${productId}/${variantId}`,
    method: 'POST',
    types: [SetProductTypes.REQUEST, SetProductTypes.SUCCESS, SetProductTypes.FAILURE],
  },
});

export const ReportOrderProblemAction = createAction('Orders', 'Report order problem');

export const reportOrderProblemAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/orders/${id}/report-problem`,
    method: 'POST',
    body: data,
    types: [ReportOrderProblemAction.REQUEST, ReportOrderProblemAction.SUCCESS, ReportOrderProblemAction.FAILURE],
  },
});

export const SkipOrderTypes = createAction('Orders', 'Skip');

export const skipOrderAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/orders/skip',
    method: 'POST',
    body: data,
    types: [SkipOrderTypes.REQUEST, SkipOrderTypes.SUCCESS, SkipOrderTypes.FAILURE],
  },
});
