import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetStatRecordsTypes = createAction('Users', 'Get stats records');

export const getStatsRecordsAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/stats/records',
    method: 'GET',
    types: [GetStatRecordsTypes.REQUEST, GetStatRecordsTypes.SUCCESS, GetStatRecordsTypes.FAILURE],
  },
});
