import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { Box } from '@bottomless/common/components';
import { getUserVendorAction } from '../../store/user';
import { useIsPrepaidSubscription } from '../../hooks/use-is-prepaid-subscription.hook';
import { useModal } from '../../hooks/use-modal.hook';
import { NewSubscriptionButton } from './NewSubscriptionButton';

export const NewSubscriptionComponent = ({
  newScaleCheckout,
  me,
  getUserVendor,
  buttonComponent: ButtonComponent = NewSubscriptionButton,
}) => {
  const [isOpen, rawToggle] = useToggle();
  const modalProps = useModal();
  const [isSbuLoading, setSbuLoading] = useState(false);
  const [isFixedLoading, setFixedLoading] = useState(false);
  const [, setCookie, removeCookie] = useCookies(['newCheckout', 'newFixedCheckout']);

  const vendorPromise = useRef();

  const populateVendorPromise = useCallback(() => {
    if (!vendorPromise.current) {
      vendorPromise.current = getUserVendor(me.vendor_id._id).then(res => res.payload);
    }
  }, [getUserVendor, me]);

  const toggle = useCallback(() => {
    populateVendorPromise();

    rawToggle();
  }, [rawToggle, populateVendorPromise]);

  const isSimpleSubscription = useMemo(() => !!me.accounts.find(account => account.shopifySubscriptionContractId), [
    me,
  ]);
  const isPrepaidSubscription = useIsPrepaidSubscription();

  const onNewSbu = useCallback(async () => {
    setSbuLoading(true);

    populateVendorPromise();

    const vendor = await vendorPromise.current;

    if (isSimpleSubscription || !vendor.useShopifyCheckout) {
      const { payload } = await newScaleCheckout({ isDynamic: true });
      const dateIn3Months = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);

      setCookie('newCheckout', payload._id, { path: '/', expires: dateIn3Months });

      window.location.href = '/collections/subscription-by-usage';
      return;
    }

    const dateIn3Months = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);

    removeCookie('newFixedCheckout', { path: '/' });
    setCookie('newSbuCheckout', 'true', { path: '/', expires: dateIn3Months });

    window.location.href = '/collections/subscription-by-usage';
  }, [setSbuLoading, setCookie, removeCookie, newScaleCheckout, isSimpleSubscription, populateVendorPromise]);

  const onNewFixed = useCallback(async () => {
    setFixedLoading(true);

    if (isSimpleSubscription) {
      const { payload } = await newScaleCheckout({ isFixed: true });
      const dateIn3Months = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);

      setCookie('newCheckout', payload._id, { path: '/', expires: dateIn3Months });

      window.location.href = '/collections/subscription-by-usage';
      return;
    }

    const dateIn3Months = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);

    removeCookie('newCheckout', { path: '/' });
    setCookie('newFixedCheckout', 'true', { path: '/', expires: dateIn3Months });

    window.location.href = '/collections/subscription-by-usage';
  }, [setFixedLoading, setCookie, removeCookie, isSimpleSubscription, newScaleCheckout]);

  const onClick = useMemo(
    () =>
      !me.vendor_id?.shopifyManifest?.useShopifyCheckout || !me.vendor_id?.shopifyManifest?.simpleSubscription
        ? onNewSbu
        : toggle,
    [me, onNewSbu, toggle]
  );

  const newScaleCopy = useMemo(
    () => (isSimpleSubscription || isPrepaidSubscription ? 'New Subscription' : 'New Scale'),
    [isSimpleSubscription, isPrepaidSubscription]
  );

  return (
    <>
      <ButtonComponent onClick={onClick} isLoading={isSbuLoading}>
        {newScaleCopy}
      </ButtonComponent>
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps} className="modal-new-subscription">
        <ModalHeader toggle={toggle}>{newScaleCopy}</ModalHeader>
        <ModalBody>
          <Row className="d-flex">
            <Col xs="12" lg="6">
              <Button color="text" onClick={onNewSbu} className="p-0 mb-4" block>
                <Box>
                  <div className="mb-2 font-weight-bold">{isSbuLoading ? 'Redirecting...' : 'By Usage'}</div>
                  <div className="small text-secondary">Smart scale triggers orders based on your usage.</div>
                </Box>
              </Button>
            </Col>
            <Col xs="12" md="6">
              <Button color="text" onClick={onNewFixed} className="p-0 mb-4" block>
                <Box>
                  <div className="mb-2 font-weight-bold">{isFixedLoading ? 'Redirecting...' : 'Fixed Frequency'}</div>
                  <div className="small text-secondary">Your get orders on chosen time frequency.</div>
                </Box>
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

NewSubscriptionComponent.propTypes = {
  newScaleCheckout: PropTypes.func.isRequired,
  me: PropTypes.shape({
    vendor_id: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      shopifyManifest: PropTypes.shape({
        useShopifyCheckout: PropTypes.bool,
        simpleSubscription: PropTypes.bool,
      }).isRequired,
    }).isRequired,
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        shopifySubscriptionContractId: PropTypes.string,
      })
    ),
  }),
  getUserVendor: PropTypes.func.isRequired,
  buttonComponent: PropTypes.elementType.isRequired,
};

export const NewSubscription = connect(null, dispatch => ({ getUserVendor: id => dispatch(getUserVendorAction(id)) }))(
  NewSubscriptionComponent
);
