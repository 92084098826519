import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Heading.scss';

export const Heading = ({ color, children, className }) => {
  return (
    <div className={classNames('d-flex justify-content-center heading-wrapper', className)}>
      <h2 className={`heading heading-${color}`}>{children}</h2>
    </div>
  );
};

Heading.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'yellow', 'green', 'red']),
};

Heading.defaultProps = {
  color: 'blue',
};
