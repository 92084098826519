import { CLEAR_CHECKOUT_PRICING_RULE, NewScaleCheckoutTypes } from './checkout.actions';

const initialState = {
  pricingRule: null,
};

export const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case NewScaleCheckoutTypes.SUCCESS:
      return {
        ...state,
        pricingRule: action.payload.pricing_rule,
        source: action.payload.source,
      };

    case CLEAR_CHECKOUT_PRICING_RULE:
      return {
        ...state,
        pricingRule: null,
        source: null,
      };

    default:
      return state;
  }
};
