import PropTypes from "prop-types";
import React from "react";
import { Image } from "./Image";

export const ProductImage = ({ product, small, width }) => {
  const imageAttr = small ? "small_image_src" : "image_src";

  const image = !product[imageAttr]
    ? null
    : product[imageAttr].match(/^(http|\/\/cdn)/)
    ? product[imageAttr]
    : process.env.REACT_APP_BACKEND_URL + product[imageAttr];

  return (
    <Image
      src={image || "/images/no-image.png"}
      alt={product.name}
      className="img-fluid"
      width={width}
    />
  );
};

ProductImage.propTypes = {
  small: PropTypes.bool,
  product: PropTypes.shape({
    small_image_src: PropTypes.string,
    image_src: PropTypes.string,
    name: PropTypes.string.isRequired
  }).isRequired,
  width: PropTypes.number.isRequired
};

ProductImage.propTypes = {
  small: true,
  width: 150
};
