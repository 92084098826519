import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import { getAccountsAction } from '../../../store/user';
import { newScaleCheckoutAction } from '../../../store/checkout/checkout.actions';

export const useAccounts = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getAccountsAction()), [dispatch]);
  const { data } = useSelector(({ user }) => ({ data: user.accounts }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  const newScaleCheckout = useCallback(data => dispatch(newScaleCheckoutAction(data)), [dispatch]);

  return { data, isLoading, error, newScaleCheckout };
};
