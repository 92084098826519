import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import { GetDiscountCodeTypes, AddDiscountCodeTypes, DeleteDiscountCodeTypes } from './discount-code.actions';

const initialState = {
  data: [],
  isLoading: false,
};

export const discountCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetDiscountCodeTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetDiscountCodeTypes.SUCCESS:
      return {
        ...state,
        data: action.payload?.discountCodes,
        isLoading: false,
      };

    case AddDiscountCodeTypes.SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        isLoading: false,
      };

    case DeleteDiscountCodeTypes.SUCCESS:
      return {
        ...state,
        data: state.data.filter(row => row._id !== action.payload._id),
        isLoading: false,
      };

    case GetDiscountCodeTypes.FAILURE:
    case DeleteDiscountCodeTypes.FAILURE:
    case AddDiscountCodeTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
