import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetDiscountCodeTypes = createAction('Discount Code', 'Get discount codes');

export const getDiscountCodeAction = () => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/discount-code`,
    method: 'GET',
    types: [GetDiscountCodeTypes.REQUEST, GetDiscountCodeTypes.SUCCESS, GetDiscountCodeTypes.FAILURE],
  },
});

export const AddDiscountCodeTypes = createAction('Discount Code', 'Apply discount');

export const addDiscountCodeAction = data => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/discount-code`,
    method: 'POST',
    body: data,
    types: [AddDiscountCodeTypes.REQUEST, AddDiscountCodeTypes.SUCCESS, AddDiscountCodeTypes.FAILURE],
  },
});

export const DeleteDiscountCodeTypes = createAction('Discount Code', 'Delete discount');

export const deleteDiscountCodeAction = ({ _id }) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/discount-code/${_id}`,
    method: 'DELETE',
    types: [DeleteDiscountCodeTypes.REQUEST, DeleteDiscountCodeTypes.SUCCESS, DeleteDiscountCodeTypes.FAILURE],
  },
});
