import { useCallback, useState } from 'react';

export const useLoadingAction = action => {
  const [isLoading, setLoading] = useState(false);

  const execute = useCallback(
    async (...data) => {
      try {
        setLoading(true);
        const res = await action(...data);
        setLoading(false);
        return res;
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [action, setLoading]
  );

  return { execute, isLoading };
};
