import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetProductsTypes = createAction('Products', 'Get all');

export const getProductsAction = ({ vendorId: vendor, type, category = '' } = { vendorId: '' }) => {
  const params = { vendor, type, ...(category && { category }) };

  return {
    [RSAA]: {
      endpoint: `/apps/bottomless/products${params ? `?${qs.stringify(params)}` : ''}`,
      method: 'GET',
      types: [GetProductsTypes.REQUEST, GetProductsTypes.SUCCESS, GetProductsTypes.FAILURE],
    },
  };
};

export const GetProductAttributesTypes = createAction('Products', 'Get attributes');

export const getProductAttributesAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/products/attributes',
    method: 'GET',
    types: [GetProductAttributesTypes.REQUEST, GetProductAttributesTypes.SUCCESS, GetProductAttributesTypes.FAILURE],
  },
});

export const GetCustomListTypes = createAction('Products', 'Get custom lists');

export const getCustomListAction = () => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/custom-lists?withProposed=true',
    method: 'GET',
    types: [GetCustomListTypes.REQUEST, GetCustomListTypes.SUCCESS, GetCustomListTypes.FAILURE],
  },
});

export const UpdateCustomListTypes = createAction('Products', 'Update custom list');

export const updateCustomListAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/custom-lists?withProposed=true',
    method: 'PATCH',
    body: data,
    types: [UpdateCustomListTypes.REQUEST, UpdateCustomListTypes.SUCCESS, UpdateCustomListTypes.FAILURE],
  },
});

export const MOVE_CUSTOM_LIST_ITEM = 'Products/Custom list move item';

export const moveCustomListItemAction = (sourceIndex, destinationIndex) => ({
  type: MOVE_CUSTOM_LIST_ITEM,
  payload: { sourceIndex, destinationIndex },
});

export const DELETE_CUSTOM_LIST_ITEM = 'Products/Custom list delete item';

export const deleteCustomListItemAction = index => ({
  type: DELETE_CUSTOM_LIST_ITEM,
  payload: { index },
});

export const UpdateLineItemsAmountsTypes = createAction('Products', 'Update line items amounts');

export const updateLineItemsAmountsAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/products/amount',
    method: 'POST',
    body: data,
    types: [
      UpdateLineItemsAmountsTypes.REQUEST,
      UpdateLineItemsAmountsTypes.SUCCESS,
      UpdateLineItemsAmountsTypes.FAILURE,
    ],
  },
});

export const OPTIMISTIC_LINE_ITEMS_AMOUNTS_UPDATE = 'Products/Optimistic line items update';

export const optimisticLineItemsAmountsUpdateAction = data => ({
  type: OPTIMISTIC_LINE_ITEMS_AMOUNTS_UPDATE,
  payload: data,
});
