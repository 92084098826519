import { useMemo } from 'react';

export const useAppContainer = () => {
  const container = useMemo(
    () =>
      document.querySelector('bottomless-app')?.shadowRoot.getElementById('bottomless-app') ||
      document.getElementById('bottomless-app'),
    []
  );

  return container;
};
