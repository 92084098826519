import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMeAction } from '../../../store/user';

export const useMe = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getMeAction()), [dispatch]);
  const { data } = useSelector(({ user }) => ({ data: user.me }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  return {
    data,
    isLoading,
    error,
  };
};
