import './polyfills';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { hotjar } from 'react-hotjar';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { AccountApp } from './AccountApp';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, allowUrls: [/shopify-front\.bottomless\.com/] });
}

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
}

const parseConfig = config => {
  try {
    return JSON.parse(config || '{}');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Incorrect config');
    return {};
  }
};

const rootElement = document.querySelector('bottomless-app');
const config = parseConfig(rootElement?.getAttribute('config'));

ReactDOM.render(
  <AccountApp config={config} />,
  document.querySelector('bottomless-app')?.shadowRoot.getElementById('bottomless-app') ||
    document.getElementById('bottomless-app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
