import React from 'react';
import { Col, Row } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { useConfigValue } from '../../hooks/use-config-value.hook';
import { NewSubscription } from '../../components/NewSubscription';
import { BottomlessAccount, NewSubscriptionButton } from './components';
import { useAccounts, useMe } from './hooks';
import './AccountPage.scss';

export const AccountPage = () => {
  const me = useMe();
  const userAccounts = useAccounts();

  const useProductAccountWidget = useConfigValue('useProductAccountWidget');
  const preventNewScale = useConfigValue('preventNewScale');
  const useEmbedAccountPicker = useConfigValue('useEmbedAccountPicker');

  if (!useEmbedAccountPicker || !userAccounts.data?.accounts?.length) {
    return null;
  }

  return (
    <div className="page-subscriptions">
      <h3>Subscriptions</h3>
      <DataLoading count={userAccounts.data?.accounts?.length || 0} isLoading={userAccounts.isLoading} />
      <div className="customer-accounts">
        <Row>
          {userAccounts.data?.accounts?.map(account => (
            <Col xs="12" md="6" key={account._id}>
              <BottomlessAccount account={{ ...account, vendor_id: me.data?.vendor_id }} />
            </Col>
          ))}
        </Row>
        {useProductAccountWidget && !preventNewScale && me.data && (
          <NewSubscription
            newScaleCheckout={userAccounts.newScaleCheckout}
            me={me.data}
            buttonComponent={NewSubscriptionButton}
          />
        )}
      </div>
    </div>
  );
};
