import { useAppContainer } from './use-app-container.hook';

const modalTransition = {
  onEntered: element => {
    const { y } = element.parentElement.getBoundingClientRect();
    element.parentElement.style.transform = `translateY(${-y}px)`;
  },
  timeout: 300,
};

const wrapClassName = 'modal-wrap';

export const useModal = () => {
  const container = useAppContainer();

  return { container, modalTransition, wrapClassName };
};
