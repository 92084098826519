import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetScaleStatusTypes = createAction('Scales', 'Get status');

export const getScaleStatusAction = () => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/scales/status`,
    method: 'GET',
    types: [GetScaleStatusTypes.REQUEST, GetScaleStatusTypes.SUCCESS, GetScaleStatusTypes.FAILURE],
  },
});

export const RequestScaleReturnTypes = createAction('Scales', 'Request return');

export const requestScaleReturnAction = () => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/scales/request-return`,
    method: 'POST',
    types: [RequestScaleReturnTypes.REQUEST, RequestScaleReturnTypes.SUCCESS, RequestScaleReturnTypes.FAILURE],
  },
});
