import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const NewScaleCheckoutTypes = createAction('Checkout', 'New scale');

export const newScaleCheckoutAction = (data = {}) => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/checkouts/new-scale',
    method: 'POST',
    body: data,
    types: [NewScaleCheckoutTypes.REQUEST, NewScaleCheckoutTypes.SUCCESS, NewScaleCheckoutTypes.FAILURE],
  },
});

export const UpdateCheckoutAddressTypes = createAction('Checkout', 'Update address');

export const updateCheckoutAddressAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/checkouts/${id}/address`,
    method: 'POST',
    body: data,
    types: [UpdateCheckoutAddressTypes.REQUEST, UpdateCheckoutAddressTypes.SUCCESS, UpdateCheckoutAddressTypes.FAILURE],
  },
});

export const GetCheckoutTypes = createAction('Checkout', 'Get');

export const getCheckoutAction = id => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/checkouts/${id}`,
    method: 'GET',
    types: [GetCheckoutTypes.REQUEST, GetCheckoutTypes.SUCCESS, GetCheckoutTypes.FAILURE],
  },
});

export const GetCheckoutShippingPriceTypes = createAction('Checkout', 'Get checkout shipping price');

export const getCheckoutShippingPriceAction = id => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/checkouts/${id}/shipping-price`,
    method: 'GET',
    types: [
      GetCheckoutShippingPriceTypes.REQUEST,
      GetCheckoutShippingPriceTypes.SUCCESS,
      GetCheckoutShippingPriceTypes.FAILURE,
    ],
  },
});

export const CompleteCheckoutTypes = createAction('Checkout', 'Complete');

export const completeCheckoutAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/checkouts/${id}/complete`,
    method: 'POST',
    body: data,
    types: [CompleteCheckoutTypes.REQUEST, CompleteCheckoutTypes.SUCCESS, CompleteCheckoutTypes.FAILURE],
  },
});

export const CLEAR_CHECKOUT_PRICING_RULE = 'Checkout/Clear pricing rule';

export const clearCheckoutPricingRuleAction = () => ({ type: CLEAR_CHECKOUT_PRICING_RULE });
