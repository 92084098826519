import { createStore as createBottomlessStore } from '@bottomless/common/store';
import { userReducer } from './user/user.reducer';
import { productReducer } from './product/product.reducer';
import { orderReducer } from './order/order.reducer';
import { scaleReducer } from './scale/scale.reducer';
import { statsReducer } from './stats/stats.reducer';
import { authReducer } from './auth/auth.reducer';
import { checkoutReducer } from './checkout/checkout.reducer';
import { discountCodeReducer } from './discount-code/discount-code.reducer';

export const createStore = () =>
  createBottomlessStore({
    auth: authReducer,
    user: userReducer,
    product: productReducer,
    order: orderReducer,
    scale: scaleReducer,
    stats: statsReducer,
    checkout: checkoutReducer,
    discountCode: discountCodeReducer,
  });
